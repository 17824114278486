export const DEFAULT_FLOW_PROJECT_NAME = 'trails';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;
export const DEFAULT_FLOW_LINK = 'https://app.trails-main-fe-stage.asqq.io/';

export const DEFAULT_FLOW = {
    branch_name: 'default',
    flow_name: 'trails__default',
    expire: 1734509861101,
    start_page: {
        id: 'start_quiz_b',
        name: 'start_quiz_b',
        config: {
            title: 'Explore hiking trails near you',
            subTitle: 'Unlock all the benefits of hiking',
            themeName: 'green',
            image: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/start_quiz_hiking_v2.webp',
        },
    },
    onboarding_flow: [
        {
            id: 'ob_start_age_b',
            name: 'ob_start_age_b',
            config: {
                title: 'Explore the right trails for you',
                cardsData: {
                    '1': {
                        title: '25-35',
                    },
                    '2': {
                        title: '36-45',
                    },
                    '3': {
                        title: '46-55',
                    },
                    '4': {
                        title: '56+',
                    },
                },
            },
        },
        {
            id: 'ob_gender_b',
            name: 'ob_gender_b',
            config: {
                screenTitle: 'Select your sex',
                options: [
                    {
                        optionTitle: 'Female',
                        optionValue: 'female',
                    },
                    {
                        optionTitle: 'Male',
                        optionValue: 'male',
                    },
                    {
                        optionTitle: 'Non-binary',
                        optionValue: 'female',
                    },
                ],
            },
        },
        {
            id: 'ob_social_proof_b',
            name: 'ob_social_proof_b_1',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/social_proof_green.webp',
                    maleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/social_proof_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: '400k hiking trails',
                screenSubtitle: 'in the Fottur app',
            },
        },
        {
            id: 'ob_welcome_b',
            name: 'ob_welcome_b',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/welcome_green.webp',
                    maleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/welcome_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: 'Welcome to your personal hiking guide!',
                screenSubtitle:
                    "We are here to ensure your hiking journey is both enjoyable and comfortable. Let's customize your hiking experience to align with your goals and preferences.",
            },
        },
        {
            id: 'ob_hiking_b',
            name: 'ob_hiking_b',
            config: {
                title: 'Have you been hiking before?',
                optionData: [
                    {
                        optionTitle: 'I’m a pro',
                        optionSubtitle: '5+ times per year',
                        optionValue: 'im_a_pro',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: 'I hike occasionally',
                        optionSubtitle: '1-5 times per year',
                        optionValue: 'i_hike_occasionally',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: "I've never hiked",
                        optionSubtitle: '',
                        optionValue: 'ive_never_hiked',
                        leftIcon: './img',
                    },
                ],
            },
        },
        {
            id: 'ob_nature_b',
            name: 'ob_nature_b',
            config: {
                title: "When's the last time you got to really experience nature?",
                optionData: [
                    {
                        optionTitle: 'This month',
                        optionValue: 'This month',
                    },
                    {
                        optionTitle: '1 month ago',
                        optionValue: '1 month ago',
                    },
                    {
                        optionTitle: 'Enjoy social and recreational activities',
                        optionValue: 'Enjoy social and recreational activities',
                    },
                    {
                        optionTitle: 'This year',
                        optionValue: 'This year',
                    },
                    {
                        optionTitle: 'More than 1 year ago',
                        optionValue: 'More than 1 year ago',
                    },
                    {
                        optionTitle: 'I can’t even remember the last time',
                        optionValue: 'I can’t even remember the last time',
                    },
                ],
            },
        },
        {
            id: 'ob_fitness_level_b',
            name: 'ob_fitness_level_b',
            config: {},
        },
        {
            id: 'ob_fitness_walking_b',
            name: 'ob_fitness_walking_b',
            config: {
                title: 'How much time do you spend walking on a typical day?',
                optionData: [
                    {
                        optionTitle: 'Fewer than 20 mins',
                        optionValue: 'fewer_than_20_mins',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: '20-60 mins',
                        optionValue: '20_60_mins',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: '1-2 hours',
                        optionValue: '1_2_hours',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: 'More than 2 hours',
                        optionValue: 'more_than_2_hours',
                        leftIcon: './img',
                    },
                ],
            },
        },
        {
            id: 'ob_smartwatch_b',
            name: 'ob_smartwatch_b',
            config: {},
        },
        {
            id: 'ob_benefits_b',
            name: 'ob_benefits_b',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_benefits_b_green.webp',
                    maleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_benefits_b_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: 'Elevate Your Hiking With Fottur',
                screenSubtitle:
                    'Fottur equips experienced hikers with advanced route planning, performance tracking, and comprehensive trail data to enhance their outdoor adventures with precision and confidence',
            },
        },
        {
            id: 'ob_reasons_b',
            name: 'ob_reasons_b_v3',
            config: {
                screenTitle: 'Select the one goal that matters to you most',
                options: [
                    {
                        optionTitle: 'Improve mental well-being',
                        optionValue: 'relieve_stress',
                    },
                    {
                        optionTitle: 'Boost health & fitness',
                        optionValue: 'improve_physic',
                    },
                    {
                        optionTitle: 'Strengthen social connections',
                        optionValue: 'new_activities',
                    },
                ],
            },
        },
        {
            id: 'ob_tags_c',
            name: 'ob_tags_c',
            config: {},
        },
        {
            id: 'ob_hiking_profile_b',
            name: 'ob_hiking_profile_b',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_benefits_b_green.webp',
                    maleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_benefits_b_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: 'Let’s learn more about your trail preferences',
                screenSubtitle:
                    'Your answers will help us better understand how you’re thinking about your hiking journey and prepare a personal plan that fit your needs',
            },
        },
        {
            id: 'ob_waterfall_b',
            name: 'ob_waterfall_b',
            config: {},
        },
        {
            id: 'ob_crowded_b',
            name: 'ob_crowded_b',
            config: {},
        },
        {
            id: 'ob_crowded_feedback_b',
            name: 'ob_crowded_feedback_b',
            config: {},
        },
        {
            id: 'ob_suitable_trails_b',
            name: 'ob_suitable_trails_b',
            config: {},
        },
        {
            id: 'ob_suitable_trails_feedback_b',
            name: 'ob_suitable_trails_feedback_b',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage:
                        'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_suitable_trails_feedback_b_green.webp',
                    maleImage:
                        'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_suitable_trails_feedback_b_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: '89% of Fottur users discover the best nearby trails',
                screenSubtitle:
                    'Our navigation system helps you find the nearest trails based on your location and provides detailed information about difficulty and safety levels',
            },
        },
        {
            id: 'ob_beforehand_b',
            name: 'ob_beforehand_b',
            config: {},
        },
        {
            id: 'ob_get_lost_b',
            name: 'ob_get_lost_b',
            config: {},
        },
        {
            id: 'ob_bad_weather_b',
            name: 'ob_bad_weather_b',
            config: {},
        },
        {
            id: 'ob_trail_difficulty_b',
            name: 'ob_trail_difficulty_b',
            config: {},
        },
        {
            id: 'ob_trail_distance_b',
            name: 'ob_trail_distance_b',
            config: {},
        },
        {
            id: 'ob_weekends_b',
            name: 'ob_weekends_b',
            config: {},
        },
        {
            id: 'ob_hiking_profile_summary_b',
            name: 'ob_hiking_profile_summary_b',
            config: {},
        },
        {
            id: 'ob_height_b',
            name: 'ob_height_b',
            config: {},
        },
        {
            id: 'ob_weight_b',
            name: 'ob_weight_b',
            config: {},
        },
        {
            id: 'ob_age_b',
            name: 'ob_age_b',
            config: {},
        },
        {
            id: 'ob_person_type_b',
            name: 'ob_person_type_v1',
            config: {
                title: 'What describes you more?',
                optionData: [
                    {
                        optionTitle: 'Result-oriented',
                        optionValue: 'Result-oriented',
                        leftIcon: './img',
                    },
                    {
                        optionTitle: 'Process-oriented',
                        optionValue: 'Process-oriented',
                        leftIcon: './img',
                    },
                ],
            },
        },
        {
            id: 'ob_person_type_feedback_b',
            name: 'ob_person_type_feedback_b',
            config: {},
        },
        {
            id: 'ob_push_b',
            name: 'ob_push_b',
            config: {},
        },
        {
            id: 'ob_push_feedback_b',
            name: 'ob_push_feedback_b',
            config: {},
        },
        {
            id: 'ob_brain_b',
            name: 'ob_brain_b',
            config: {},
        },
        {
            id: 'ob_brain_feedback_b',
            name: 'ob_brain_feedback_b',
            config: {
                screenSubtitleHighlights: [],
                imgData: {
                    femaleImage:
                        'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_brain_feedback_b_green.webp',
                    maleImage:
                        'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_brain_feedback_b_green.webp',
                },
                isFullWidthImage: true,
                screenTitle: 'Regular hiking improves your brain performance by 30%',
                screenSubtitle:
                    'Numerous studies have demonstrated that spending time in nature, particularly when engaged in physical activities like hiking and walking, can significantly enhance cognitive function.',
            },
        },
        {
            id: 'ob_stress_level_b',
            name: 'ob_stress_level_b',
            config: {},
        },
        {
            id: 'ob_feelings_b',
            name: 'ob_feelings_b',
            config: {
                title: 'During my hiking journey, I want to feel...',
                subtitle: 'Select all that apply',
                optionData: [
                    {
                        optionTitle: 'Happy',
                        optionValue: 'Happy',
                    },
                    {
                        optionTitle: 'Calm',
                        optionValue: 'Calm',
                    },
                    {
                        optionTitle: 'Excited',
                        optionValue: 'Excited',
                    },
                    {
                        optionTitle: 'Energized',
                        optionValue: 'Energized',
                    },
                    {
                        optionTitle: 'Refreshed',
                        optionValue: 'Refreshed',
                    },
                    {
                        optionTitle: 'Connected with nature',
                        optionValue: 'Connected with nature',
                    },
                    {
                        optionTitle: 'None of the above',
                        optionValue: 'None of the above',
                    },
                ],
            },
        },
        {
            id: 'ob_socialize_b',
            name: 'ob_socialize_b_1',
            config: {
                screenTitle:
                    '“My sedentary lifestyle has affected my ability to socialize or engage with friends and family.”',
                screenFemaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_socialize_b_green.webp',
                screenMaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_socialize_b_green.webp',
                screenSubtitle: 'Does the statement above describe you?',
            },
        },
        {
            id: 'ob_busy_b',
            name: 'ob_busy_b_1',
            config: {
                screenTitle:
                    '“I have been thinking about hiking for a while but life is so busy I find myself putting convenience first.”',
                screenFemaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_busy_b_green.webp',
                screenMaleImage: 'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_busy_b_green.webp',
                screenSubtitle: 'Does the statement above describe you?',
            },
        },
        {
            id: 'ob_inner_power_b',
            name: 'ob_inner_power_b_1',
            config: {
                screenTitle: '“I usually feel that I lack the inner power to face challenges.”',
                screenFemaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_inner_power_b_green.webp',
                screenMaleImage:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/Trails/ob_inner_power_b_green.webp',
                screenSubtitle: 'Does the statement above describe you?',
            },
        },
        {
            id: 'ob_psychological_profile_b',
            name: 'ob_psychological_profile_b',
            config: {},
        },
        {
            id: 'ob_challenges_b',
            name: 'ob_challenges_b',
            config: {
                screenTitle: 'What has stopped you from hiking more frequently?',
                screenSubtitle: 'Select all that apply',
                options: [
                    {
                        optionTitle: 'Lack of time',
                        optionValue: 'Lack of time',
                    },
                    {
                        optionTitle: 'Long distances',
                        optionValue: 'Long distances',
                    },
                    {
                        optionTitle: 'Bad weather',
                        optionValue: 'Bad weather',
                    },
                    {
                        optionTitle: 'Lack of motivation',
                        optionValue: 'Lack of motivation',
                    },
                    {
                        optionTitle: 'None of the above',
                        optionValue: 'None of the above',
                    },
                ],
            },
        },
        {
            id: 'ob_good_hands_b',
            name: 'ob_good_hands_b',
            config: {},
        },
        {
            id: 'creating_g',
            name: 'creating_g',
            config: {},
        },
        {
            id: 'ob_email_b',
            name: 'ob_email_b_v1',
            config: {},
        },
        {
            id: 'email_consent_b',
            name: 'email_consent_b',
            config: {},
        },
        {
            id: 'result_b',
            name: 'result_b',
            config: {
                graphTitle: 'Your Anxiety Level',
                pageTitle: 'Your 4-week Hiking Plan to break free from anxiety is ready!',
            },
        },
        {
            id: 'ob_commitment_b',
            name: 'ob_commitment_b',
            config: {},
        },
    ],
    payment_flow: [
        {
            id: 'payment_Ag_checkout_Alpha2',
            name: 'payment_Ag_checkout_Alpha2',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7a83d865-4be2-41e1-92d6-072145e50d9e',
                    name: 'One-week intro (6.93) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 99,
                    start_price: 1777,
                    price: 693,
                    sign: '$',
                    code: 'USD',
                    ltv: 3632,
                },
                {
                    payment_type: 'subscription',
                    id: 'd4a2a74c-19d7-4574-91c0-5168434cfd6f',
                    name: 'One-month intro (15.19) + One-month (38.95)',
                    trial: 30,
                    period: 30,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 49,
                    start_price: 3895,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 4895,
                },
                {
                    payment_type: 'subscription',
                    id: '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
                    name: 'Three-months intro (25.99) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 29,
                    start_price: 6665,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 5101,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 1777,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 4393,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-months intro (38.95) - One-months (38.95)',
                    trial: 31,
                    period: 30,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 7111,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-months intro (66.65) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 7956,
                },
            ],
            config: {
                screenTitle: 'Your trail walking plan is ready!',
                hideSecondProductBlock: false,
                cardsTitle: [
                    {
                        title: 'Start your positive change',
                    },
                    {
                        title: 'Get visible results',
                    },
                    {
                        title: 'Become fit and toned',
                    },
                ],
            },
        },
        {
            id: 'upsell_b',
            name: 'upsell_b',
            parent_id: 'payment_Ag_checkout_Alpha2',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '9426e04d-0fda-46aa-999a-0b7e3e2777ee',
                    name: 'One-month intro (15.19) + One-month (30.99) main parks_offline',
                    trial: 31,
                    period: 31,
                    product_code: 'parks_offline',
                    is_preselected: true,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1519,
                    start_price: 3099,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 4113,
                },
                {
                    payment_type: 'subscription',
                    id: '8efc395d-df3a-453d-93a6-9e431d2f0f38',
                    name: 'One-month intro (11.49) + One-month (15.99) main parks',
                    trial: 31,
                    period: 31,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1149,
                    start_price: 1599,
                    price: 1149,
                    sign: '$',
                    code: 'USD',
                    ltv: 2379,
                },
                {
                    payment_type: 'subscription',
                    id: '7d9d084b-22ad-490f-b591-1d5fb9a7f039',
                    name: 'One-month intro (11.49) + One-month (15.99) main offline',
                    trial: 31,
                    period: 31,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1149,
                    start_price: 1599,
                    price: 1149,
                    sign: '$',
                    code: 'USD',
                    ltv: 2379,
                },
                {
                    payment_type: 'subscription',
                    id: '33db9957-a740-4fec-bc2b-fd6983d2ba5f',
                    name: 'One-month intro (9.99) + One-month (30.99) offer parks_offline',
                    trial: 31,
                    period: 31,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 3099,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 3748,
                },
                {
                    payment_type: 'subscription',
                    id: '4db6d16c-9e18-4b71-9215-c70d66145068',
                    name: 'One-month intro (7.99) + One-month (15.99) offer parks',
                    trial: 31,
                    period: 31,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 799,
                    start_price: 1599,
                    price: 799,
                    sign: '$',
                    code: 'USD',
                    ltv: 2133,
                },
                {
                    payment_type: 'subscription',
                    id: '640787cf-4cd4-4200-9cf6-b1cdee257dc3',
                    name: 'One-month intro (7.99) + One-month (15.99) offer offline',
                    trial: 31,
                    period: 31,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 799,
                    start_price: 1599,
                    price: 799,
                    sign: '$',
                    code: 'USD',
                    ltv: 2133,
                },
                {
                    payment_type: 'subscription',
                    id: 'a41fab67-2ff8-481b-9f3c-3e4b6394b8ec',
                    name: 'Three-months intro (25.99) + Three-months (53.19) main parks_offline',
                    trial: 90,
                    period: 90,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 2599,
                    start_price: 5319,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 4439,
                },
                {
                    payment_type: 'subscription',
                    id: 'b211f26a-0f4b-4678-9e0c-250d7e4da7ab',
                    name: 'Three-months intro (19.99) + Three-months (27.99) main parks',
                    trial: 90,
                    period: 90,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1999,
                    start_price: 2799,
                    price: 1999,
                    sign: '$',
                    code: 'USD',
                    ltv: 2779,
                },
                {
                    payment_type: 'subscription',
                    id: '3377be31-1f6c-4f6e-b663-9d897c9bc518',
                    name: 'Three-months intro (19.99) + Three-months (27.99) main offline',
                    trial: 90,
                    period: 90,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1999,
                    start_price: 2799,
                    price: 1999,
                    sign: '$',
                    code: 'USD',
                    ltv: 2779,
                },
                {
                    payment_type: 'subscription',
                    id: '1344e1dd-bd6b-4b80-8cce-b4fa23e0d696',
                    name: 'Three-months intro (16.99) + Three-months (53.19) offer parks_offline',
                    trial: 90,
                    period: 90,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1699,
                    start_price: 5319,
                    price: 1699,
                    sign: '$',
                    code: 'USD',
                    ltv: 3807,
                },
                {
                    payment_type: 'subscription',
                    id: '5c0074a8-059d-4ff5-aeba-c97759736635',
                    name: 'Three-months intro (12.99) + Three-months (27.99) offer parks',
                    trial: 90,
                    period: 90,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1299,
                    start_price: 2799,
                    price: 1299,
                    sign: '$',
                    code: 'USD',
                    ltv: 2288,
                },
                {
                    payment_type: 'subscription',
                    id: 'e3bad765-13be-4667-9a17-ee488fad9e0b',
                    name: 'Three-months intro (12.99) + Three-months (27.99) offer offline',
                    trial: 90,
                    period: 90,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1299,
                    start_price: 2799,
                    price: 1299,
                    sign: '$',
                    code: 'USD',
                    ltv: 2288,
                },
            ],
            config: {},
        },
        {
            id: 'offer_Ag_checkout_Alpha2',
            name: 'offer_Ag_checkout_Alpha2',
            parent_id: 'payment_Ag_checkout_Alpha2',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '9a0f3d55-3279-4563-8c76-11520a1ea9eb',
                    name: 'One-week intro (5.15) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 69,
                    start_price: 3895,
                    price: 515,
                    sign: '$',
                    code: 'USD',
                    ltv: 3507,
                },
                {
                    payment_type: 'subscription',
                    id: '2efa5e56-620d-4c77-b691-8547ce397960',
                    name: 'One-months intro (11.29) - One-months (38.95)',
                    trial: 30,
                    period: 30,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 36,
                    start_price: 3895,
                    price: 1129,
                    sign: '$',
                    code: 'USD',
                    ltv: 4622,
                },
                {
                    payment_type: 'subscription',
                    id: '4884b8ac-b541-4bdc-94c7-139521f63ac6',
                    name: 'Three-months intro (19.29) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 19,
                    start_price: 6665,
                    price: 1929,
                    sign: '$',
                    code: 'USD',
                    ltv: 4630,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 1777,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 4393,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-months intro (38.95) - One-months (38.95)',
                    trial: 31,
                    period: 30,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 7111,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-months intro (66.65) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 7956,
                },
            ],
            config: {
                screenTitle: 'Your trail walking plan is ready!',
                hideSecondProductBlock: false,
                cardsTitle: [
                    {
                        title: 'Start your positive change',
                    },
                    {
                        title: 'Get visible results',
                    },
                    {
                        title: 'Become fit and toned',
                    },
                ],
            },
        },
        {
            id: 'upsell_one_time_b',
            name: 'upsell_one_time_b',
            parent_id: 'upsell_b',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan_2',
                    name: 'guide_meal_plan_2',
                    trial: 0,
                    period: 1,
                    product_code: 'meal_plan',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_one_time_b',
            name: 'upsell_one_time_b',
            parent_id: 'upsell_b',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan_2',
                    name: 'guide_meal_plan_2',
                    trial: 0,
                    period: 1,
                    product_code: 'meal_plan',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_b',
            name: 'upsell_b',
            parent_id: 'offer_Ag_checkout_Alpha2',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '9426e04d-0fda-46aa-999a-0b7e3e2777ee',
                    name: 'One-month intro (15.19) + One-month (30.99) main parks_offline',
                    trial: 31,
                    period: 31,
                    product_code: 'parks_offline',
                    is_preselected: true,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1519,
                    start_price: 3099,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 4113,
                },
                {
                    payment_type: 'subscription',
                    id: '8efc395d-df3a-453d-93a6-9e431d2f0f38',
                    name: 'One-month intro (11.49) + One-month (15.99) main parks',
                    trial: 31,
                    period: 31,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1149,
                    start_price: 1599,
                    price: 1149,
                    sign: '$',
                    code: 'USD',
                    ltv: 2379,
                },
                {
                    payment_type: 'subscription',
                    id: '7d9d084b-22ad-490f-b591-1d5fb9a7f039',
                    name: 'One-month intro (11.49) + One-month (15.99) main offline',
                    trial: 31,
                    period: 31,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1149,
                    start_price: 1599,
                    price: 1149,
                    sign: '$',
                    code: 'USD',
                    ltv: 2379,
                },
                {
                    payment_type: 'subscription',
                    id: '33db9957-a740-4fec-bc2b-fd6983d2ba5f',
                    name: 'One-month intro (9.99) + One-month (30.99) offer parks_offline',
                    trial: 31,
                    period: 31,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 3099,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 3748,
                },
                {
                    payment_type: 'subscription',
                    id: '4db6d16c-9e18-4b71-9215-c70d66145068',
                    name: 'One-month intro (7.99) + One-month (15.99) offer parks',
                    trial: 31,
                    period: 31,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 799,
                    start_price: 1599,
                    price: 799,
                    sign: '$',
                    code: 'USD',
                    ltv: 2133,
                },
                {
                    payment_type: 'subscription',
                    id: '640787cf-4cd4-4200-9cf6-b1cdee257dc3',
                    name: 'One-month intro (7.99) + One-month (15.99) offer offline',
                    trial: 31,
                    period: 31,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 799,
                    start_price: 1599,
                    price: 799,
                    sign: '$',
                    code: 'USD',
                    ltv: 2133,
                },
                {
                    payment_type: 'subscription',
                    id: 'a41fab67-2ff8-481b-9f3c-3e4b6394b8ec',
                    name: 'Three-months intro (25.99) + Three-months (53.19) main parks_offline',
                    trial: 90,
                    period: 90,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 2599,
                    start_price: 5319,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 4439,
                },
                {
                    payment_type: 'subscription',
                    id: 'b211f26a-0f4b-4678-9e0c-250d7e4da7ab',
                    name: 'Three-months intro (19.99) + Three-months (27.99) main parks',
                    trial: 90,
                    period: 90,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1999,
                    start_price: 2799,
                    price: 1999,
                    sign: '$',
                    code: 'USD',
                    ltv: 2779,
                },
                {
                    payment_type: 'subscription',
                    id: '3377be31-1f6c-4f6e-b663-9d897c9bc518',
                    name: 'Three-months intro (19.99) + Three-months (27.99) main offline',
                    trial: 90,
                    period: 90,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1999,
                    start_price: 2799,
                    price: 1999,
                    sign: '$',
                    code: 'USD',
                    ltv: 2779,
                },
                {
                    payment_type: 'subscription',
                    id: '1344e1dd-bd6b-4b80-8cce-b4fa23e0d696',
                    name: 'Three-months intro (16.99) + Three-months (53.19) offer parks_offline',
                    trial: 90,
                    period: 90,
                    product_code: 'parks_offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1699,
                    start_price: 5319,
                    price: 1699,
                    sign: '$',
                    code: 'USD',
                    ltv: 3807,
                },
                {
                    payment_type: 'subscription',
                    id: '5c0074a8-059d-4ff5-aeba-c97759736635',
                    name: 'Three-months intro (12.99) + Three-months (27.99) offer parks',
                    trial: 90,
                    period: 90,
                    product_code: 'parks',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1299,
                    start_price: 2799,
                    price: 1299,
                    sign: '$',
                    code: 'USD',
                    ltv: 2288,
                },
                {
                    payment_type: 'subscription',
                    id: 'e3bad765-13be-4667-9a17-ee488fad9e0b',
                    name: 'Three-months intro (12.99) + Three-months (27.99) offer offline',
                    trial: 90,
                    period: 90,
                    product_code: 'offline',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 1299,
                    start_price: 2799,
                    price: 1299,
                    sign: '$',
                    code: 'USD',
                    ltv: 2288,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_one_time_b',
            name: 'upsell_one_time_b',
            parent_id: 'upsell_b',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan_2',
                    name: 'guide_meal_plan_2',
                    trial: 0,
                    period: 1,
                    product_code: 'meal_plan',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_one_time_b',
            name: 'upsell_one_time_b',
            parent_id: 'upsell_b',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan_2',
                    name: 'guide_meal_plan_2',
                    trial: 0,
                    period: 1,
                    product_code: 'meal_plan',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
    ],
};
