export const SET_EMAIL = 'USER/SET_EMAIL';
export const SET_USER_ID = 'USER/SET_USER_ID';
export const SET_USER_TOKEN = 'USER/SET_USER_TOKEN';
export const SET_GUIDE_ID = 'USER/SET_GUIDE_ID';
export const SET_COUNTRY = 'USER/SET_COUNTRY';

export const UPDATE_INFO = 'USER/UPDATE_INFO';
export const UPDATE_INFO_FAIL = 'USER/UPDATE_INFO_FAIL';

export const SIGN_UP = 'USER/SIGN_UP';
export const SIGN_UP_SUCCESS = 'USER/SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'USER/SIGN_UP_FAIL';

export const LOG_IN = 'USER/LOG_IN';
export const LOG_IN_SUCCESS = 'USER/LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'USER/LOG_IN_FAIL';
export const LOG_OUT = 'USER/LOG_OUT';

export const FORGOT_PASSWORD = 'USER/FORGOT_PASSWORD';

export const DELETE_USER_DATA = 'USER/DELETE_USER_DATA';

export const UPDATE_MAILING_PREFERENCES = 'UPDATE_MAILING_PREFERENCES';

export const SET_IS_PAID = 'USER/SET_IS_PAID';
export const SET_UPDATED_AT_TIME = 'USER/SET_UPDATED_AT_TIME';

export const SET_IS_AUTOLOGIN_COMPLETE = 'USER/SET_IS_AUTOLOGIN_COMPLETE';

export const SET_PLATFORM = 'USER/SET_PLATFORM';

export const SET_IS_LOADING = 'USER/SET_IS_LOADING';

export const GET_DEEP_LINK = 'USER/GET_DEEP_LINK';
export const GET_DEEP_LINK_SUCCESS = 'USER/GET_DEEP_LINK_SUCCESS';

export const SET_IS_PERSONAL_PLAN = 'USER/SET_IS_PERSONAL_PLAN';

export const COMPLETE_REGISTRATION = 'USER/COMPLETE_REGISTRATION';

export const GET_SUBSCRIPTIONS = 'USER/GET_SUBSCRIPTIONS';
export const SET_SUBSCRIPTIONS = 'USER/SET_SUBSCRIPTIONS';
export const CANCEL_SUBSCRIPTION = 'USER/CANCEL_SUBSCRIPTION';
export const SET_SUBSCRIPTIONS_ERROR = 'USER/SET_SUBSCRIPTIONS_ERROR';
