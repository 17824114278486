import { PageType } from 'constants/analytics';

import { getGTMDataFromCurrentProduct } from '../helpers';

import { CurrentProduct } from 'types/payments/payments';
import { PurchaseData } from '../types';

import { trackGTM } from './mainTrackers';

export const sendGTMStartPageEvent = () => {
    trackGTM({
        name: 'onboarding_start',
        data: {
            pageType: PageType.Onboarding,
        },
    });
};

export const sendGTMLeadEvent = () => {
    trackGTM({
        name: 'lead',
        data: {
            pageType: PageType.Onboarding,
        },
    });
};

export const sendGTMPaymentPageEvent = (page: PageType) => {
    trackGTM({
        name: 'payment_screen_load',
        type: 'pageview_event',
        data: {
            pageType: page,
        },
    });
};

export const sendGTMRegistrationPageEvent = (page: PageType) => {
    trackGTM({
        name: 'registration_screen_load',
        type: 'pageview_event',
        data: {
            pageType: page,
        },
    });
};

export const sendGTMDownloadPageEvent = (page: PageType) => {
    trackGTM({
        name: 'download_screen_load',
        type: 'pageview_event',
        data: {
            pageType: page,
        },
    });
};

export const sendGTMDownloadClickEvent = (page: PageType) => {
    trackGTM({
        name: 'download_page__click',
        type: 'click_event',
        data: {
            pageType: page,
        },
    });
};

export const sendGTMCheckoutEvent = (currentProduct: CurrentProduct, currency: string) => {
    trackGTM({
        name: 'checkout_screen_load',
        type: 'pageview_event',
        data: {
            pageType: PageType.Checkout,
            ...getGTMDataFromCurrentProduct(currentProduct, currency),
        },
    });
};

export const sendGTMPurchaseEvent = (data: PurchaseData, pageType?: PageType) => {
    trackGTM({
        name: 'purchase',
        data: {
            pageType: pageType || PageType.Payment,
            ...data,
        },
    });
};
