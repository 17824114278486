// import { upsellRoutes } from './upsellRoutes';
// import { startRoutes } from './startRoutes';
// import { resultRoutes } from './resultRoutes';
// import { paymentRoutes } from './paymentRoutes';
// import { otherRoutes } from './otherRoutes';
// import { onboardingRoutes } from './onboardingRoutes';
import { cabinetRoutes } from './cabinetRoutes';

export const allRoutes = [
    // ...startRoutes,
    // ...onboardingRoutes,
    // ...upsellRoutes,
    // ...paymentRoutes,
    // ...otherRoutes,
    // ...resultRoutes,
    ...cabinetRoutes,
];
