export const cabinetRoutes = [
    {
        path: 'CabinetPages/Unsubscribe',
        route: 'cancel',
    },
    {
        path: 'CabinetPages/Login',
        route: 'login',
    },
    {
        path: 'CabinetPages/UserManager',
        route: 'main',
    },
    {
        path: 'GeneralPages/OutOfService',
        route: 'out-of-service',
    },
];
